<template>
  <vue3-chart-js
    v-show="chartData.data.datasets?.length"
    ref="chartDataRef"
    v-bind="{ ...chartData }"
    :id="id"
  />
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { ref, reactive } from "vue";
import dataLabels from "chartjs-plugin-datalabels";

export default {
  components: {
    Vue3ChartJs,
  },
  setup(props) {
    const chartDataRef = ref(null);

    const chartData = reactive({
      type: props.type,
      options: props.options,
      data: {
        labels: [],
        datasets: [],
      },
      plugins: [dataLabels],
    });

    const getData = async () => {
      if (props.options) {
        chartData.options = props.options;
      }
      chartData.data.labels = props.labels;
      chartData.data.datasets = props.datasets;
      chartDataRef.value.update(100);
    };
    
    return {
      chartData,
      chartDataRef,
      getData,
    };
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "chartjs",
    },
    type: {
      type: String,
      required: true,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    datasets: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: function () {
        return {
          responsive: true,
          maintainAspectRatio: false,
        };
      },
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    datasets: {
      handler(val) {
        console.log('data-set', val);
        this.getData();
      },
      deep: true
    },
    labels: {
      handler(val) {
        this.getData();
        console.log('data-set 11', val);
      },
      deep: true
    },
  },
};
</script>
